import { useRouter } from 'next/router';
import { useCallback } from 'react';

import useNavigate from '@client/hooks/useNavigate';

import { signOut } from '@client/redux/auth/thunks';

import { CommonRoutes } from '@client/constants/routes';

import { useBrand } from '@client/hooks/useBrand';

import { WELCOME_ADVICE_LINKS } from '@client/modules/Welcome/constants';

import { BRAND } from '@client/constants/general';

import { openInNewTab } from '@client/utils/openInNewTab';

import { useAppDispatch } from '@client/hooks/useAppDispatch';

import {
  IMobileMenuLinkProps,
  ITopNavbarLinkProps,
} from '@client/components/Navbar/interfaces';
import { ExitIcon } from '@client/components/Icons/svg';
import { useMixpanel } from '@client/modules/UserAnalytics/hooks';

import {
  PROFILE_MIXPANEL_EVENTS,
  PROFILE_MIXPANEL_EVENT_PROPERTIES,
  PROFILE_PATHNAME,
} from '@client/modules/Profile/constants';

import useAuroraBilling from '@client/hooks/useAuroraBilling';

import {
  TOP_MENU_MIXPANEL_EVENTS,
  TOP_MENU_MIXPANEL_EVENT_PROPERTIES,
} from '../constants';

export const useTopNavbar = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { id } = useBrand();
  const { navigate } = useNavigate();
  const mixpanel = useMixpanel();
  const { isAuroraBilling } = useAuroraBilling();

  const handleSignOut = () => {
    dispatch(signOut({ global: false }));
    sessionStorage.clear();
    navigate(CommonRoutes.getLoginRoute());

    mixpanel?.track(TOP_MENU_MIXPANEL_EVENTS.MENU_SELECT, {
      menuSelection: TOP_MENU_MIXPANEL_EVENT_PROPERTIES.LOGOUT_LINK,
    });
  };

  const handleMenuLinkClick = useCallback(
    (mixpanelEvent: string, pageSelected: string) => () => {
      mixpanel?.track(mixpanelEvent, {
        menuSelection: pageSelected,
        pageName: router.pathname,
      });
    },
    [mixpanel, router.pathname],
  );

  const handleHelpLinkClick = () => {
    const brandName = BRAND[id];
    const selfHelpLink = WELCOME_ADVICE_LINKS[brandName];
    openInNewTab(selfHelpLink);

    mixpanel?.track(TOP_MENU_MIXPANEL_EVENTS.MENU_SELECT, {
      menuSelection: TOP_MENU_MIXPANEL_EVENT_PROPERTIES.HELP_AND_SUPPORT_LINK,
    });
  };

  const topNavLinks: ITopNavbarLinkProps[] = [
    {
      href: '/profile',
      name: 'Profile',
      onClick: handleMenuLinkClick(
        TOP_MENU_MIXPANEL_EVENTS.MENU_SELECT,
        TOP_MENU_MIXPANEL_EVENT_PROPERTIES.PROFILE_LINK,
      ),
      order: 1,
    },
    {
      href: '',
      name: 'Help and support',
      onClick: handleHelpLinkClick,
      order: 0,
    },
    { href: '/login', name: 'Logout', onClick: handleSignOut, order: 2 },
  ];

  const mobileMenuLinksDefault: IMobileMenuLinkProps[] = [
    {
      href: '',
      name: 'Help and support',
      onClick: handleHelpLinkClick,
      isBottom: true,
      order: 0,
    },

    {
      href: PROFILE_PATHNAME.PLAN,
      name: 'Plan',
      onClick: handleMenuLinkClick(
        PROFILE_MIXPANEL_EVENTS.MENU_SELECT,
        PROFILE_MIXPANEL_EVENT_PROPERTIES.PLAN_LINK,
      ),
      order: 1,
    },
    {
      href: PROFILE_PATHNAME.PROFILE,
      name: 'Profile',
      onClick: handleMenuLinkClick(
        PROFILE_MIXPANEL_EVENTS.MENU_SELECT,
        PROFILE_MIXPANEL_EVENT_PROPERTIES.PROFILE_LINK,
      ),
      order: 0,
    },

    {
      href: '/login',
      name: 'Logout',
      onClick: handleSignOut,
      isBottom: true,
      order: 1,
      icon: ExitIcon,
    },
  ];

  const mobileMenuLinksAuroraBilling: IMobileMenuLinkProps[] = [
    {
      href: PROFILE_PATHNAME.PAYMENT_DETAILS,
      name: 'Payment details',
      onClick: handleMenuLinkClick(
        PROFILE_MIXPANEL_EVENTS.MENU_SELECT,
        PROFILE_MIXPANEL_EVENT_PROPERTIES.PAYMENT_DETAILS_LINK,
      ),
      order: 2,
    },
    {
      href: PROFILE_PATHNAME.BILLING_HISTORY,
      name: 'Billing history',
      onClick: handleMenuLinkClick(
        PROFILE_MIXPANEL_EVENTS.MENU_SELECT,
        PROFILE_MIXPANEL_EVENT_PROPERTIES.BILLING_HISTORY_LINK,
      ),
      order: 3,
    },
  ];

  const mobileMenuLinks = isAuroraBilling
    ? [...mobileMenuLinksDefault, ...mobileMenuLinksAuroraBilling]
    : mobileMenuLinksDefault;

  return {
    topNavLinks,
    mobileMenuLinks,
  };
};
