import { DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';

export const initDatadog = (dataDogVersion: string) => {
  const dataDogConfig = {
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'self-serve-ui',
    version: dataDogVersion,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
    silentMultipleInit: true,
    env: process.env.NEXT_PUBLIC_DD_ENV as string,
  };

  datadogRum.init(dataDogConfig);
};
