import React, { useEffect } from 'react';

import { useRouter } from 'next/router';

import { CommonRoutes } from '@client/constants/routes';

import { StyledClaimsJourneyWrapper } from './styles';

const ClaimsJourneyWrapper: React.FC = ({ children }) => {
  const router = useRouter();
  const routes = [
    CommonRoutes.getProblemRoute(),
    CommonRoutes.getTellUsMoreRoute(),
    CommonRoutes.getEngineerAccessRoute(),
    CommonRoutes.getAdditionalNeedsRoute(),
    CommonRoutes.getContactInformationRoute(),
    CommonRoutes.getProvidedInformationRoute(),
    CommonRoutes.getSummaryRoute(),
    CommonRoutes.getContactUsRoute(),
  ];
  const confirmMessage =
    'You have not completed your request for an engineer. Are you sure you want to leave?';

  useEffect(() => {
    if (
      router.pathname === CommonRoutes.getSummaryRoute() ||
      router.pathname === CommonRoutes.getContactUsRoute() ||
      !routes.includes(router.pathname)
    ) {
      return;
    }

    const handleRouteChangeStart = (route: string) => {
      const pathname = route.split('?')[0];

      if (!routes.includes(pathname) && !window.confirm(confirmMessage)) {
        router.events.emit('routeChangeError');
        router.replace(router, router.asPath, { shallow: true });
        throw 'Abort route change. Please ignore this error.';
      }
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = confirmMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.pathname]);

  return <StyledClaimsJourneyWrapper>{children}</StyledClaimsJourneyWrapper>;
};

export { ClaimsJourneyWrapper };
