import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

export interface DataDogRum {
  addError: (errorMessage: string, data?: Record<string, unknown>) => void;
  addAction: (actionName: string, data?: Record<string, unknown>) => void;
}

export const useDataDog = (): DataDogRum => {
  const handleAddError = useCallback(
    (errorMessage: string, data?: Record<string, unknown>) => {
      datadogRum.addError(new Error(errorMessage), data);
    },
    [],
  );

  const handleAddAction = useCallback(
    (actionName: string, data?: Record<string, unknown>) => {
      datadogRum.addAction(actionName, data);
    },
    [],
  );

  return {
    addError: handleAddError,
    addAction: handleAddAction,
  };
};
