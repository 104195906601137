import React from 'react';

import { useRouter } from 'next/router';

import { ProfileLayout } from '@client/modules/Profile/ProfileLayout';

import { IProfilePageProps } from '@client/modules/Profile/interfaces';
import MainContainer from '@client/components/Layout/MainContainer';

import ProfileHead from '@client/modules/Profile/ProfileHead';

import { RaiseClaimWrapper } from './styles';

const Wrapper: React.FC<IProfilePageProps> = ({ children, ...pageProps }) => {
  const router = useRouter();

  if (
    router.pathname === '/profile' ||
    router.pathname === '/profile/plan' ||
    router.pathname === '/profile/payment-details' ||
    router.pathname === '/profile/billing-history' ||
    router.pathname === '/profile/renew-plan'
  ) {
    return (
      <>
        <ProfileHead />
        <MainContainer>
          <ProfileLayout {...pageProps}>{children}</ProfileLayout>
        </MainContainer>
      </>
    );
  }

  if (router.pathname.startsWith('/profile')) {
    return (
      <>
        <ProfileHead />
        <MainContainer>{children}</MainContainer>
      </>
    );
  }

  return <RaiseClaimWrapper id="Claims Wrapper">{children}</RaiseClaimWrapper>;
};

export { Wrapper };
