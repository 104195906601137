import { datadogRum } from '@datadog/browser-rum';
import React from 'react';

import { useAppSelector } from '../../hooks/useAppSelector';
import { selectCurrentUser } from '../../redux/auth/selectors';

const DatadogProvider: React.FC = ({ children }) => {
  const currentUser = useAppSelector(selectCurrentUser);

  datadogRum.setUser({
    email: currentUser?.getUsername() ?? '',
  });
  datadogRum.startSessionReplayRecording();

  return <>{children}</>;
};

export default DatadogProvider;
